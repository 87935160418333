import React, {useEffect, useState} from 'react';
import "../assets/scss/_home.scss"
import "../assets/scss/_layout.scss"
import "../assets/scss/_slider.scss"
import "../assets/scss/_packages.scss"
import EarlyAccessModal from "../components/modals/EarlyAccessModal";
// import {Waypoint} from 'react-waypoint';
// import Lottie from "lottie-react";
// import LogoAnimation from "../assets/images/home/animation.json"
// import ForceFi from "../assets/icons/rotation-logo.svg"
import YES from "../assets/icons/home/Yes.svg"
import NO from "../assets/icons/home/No.svg"
import Topology from "../assets/images/home/Topology.svg"
import BurstCircle from "../assets/images/home/Burst-circle.svg"
import Looper from "../assets/images/home/Looper.svg"
import Progress from "../assets/images/home/Progress.svg"


// import Tokenomics from "../assets/images/cabinet/tokenomics.svg";
// import Arbitrum from "../assets/icons/arbitrum.svg"
import Socials from "../assets/images/home/info-graphic/social.svg";
import Rocket from "../assets/images/home/info-graphic/rocket.svg";
import CuratorsImg from "../assets/images/home/info-graphic/curators.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {useWeb3Modal} from "@web3modal/react";
import {useAccount} from "wagmi";
import {setLocal} from "web3modal";
import CuratorSlider from "../components/home/CuratorSlider";
import ConnectButton from "../utils/ConnectButton";
import Roadmap from "../components/home/Roadmap";
import Partners from "../components/home/Partners";
import Distribution from "../components/home/Distribution";
import TokenUntility from "../components/home/TokenUntility";

export default function Home() {
    const [getEarlyAccess, setGetEarlyAccess] = useState(false);

    const { open: openWeb3Modal, close } = useWeb3Modal()
    const navigate = useNavigate();
    const { isConnected } = useAccount()
    const location = useLocation();
    const params = new URLSearchParams(location.search)
    function handleNavigateToRegistration() {
        setLocal("homePageNavigation", true);
        isConnected ? navigate("/registration") : openWeb3Modal();
    }


    useEffect(() => {
        if (localStorage.getItem("referral") !== undefined && params.get("ref") !== null){
            setLocal("referral", params.get("ref"))
        }
    })

    return (
        <>
            <div className="forcefi-n">
                {/*<img src={Background} alt="" className="-hero"/>*/}
                <div className='-hero'>

                    <div className="-wrapper">
                        <div className='-text-block'>
                            <div className="-text green">JOIN US TODAY!</div>
                            <div className="-title">Fund and scale your Web3 start-up</div>
                            <div className="-text">Forcefi bridges early-stage projects with contributors and industry pros
                            </div>
                            <ConnectButton buttonType={'-button'} homePageNavigation={true} buttonName={"Get Free Access"}/>
                        </div>
                        <div className="info-graphics">
                            <div className="-card">
                                <img src={Socials} alt="" className="-img"/>
                            </div>
                            <div className="-cards">
                                <div className="-card">
                                    <img src={CuratorsImg} alt="" className="-icon"/>
                                    <div className="-text">Total curators</div>
                                    <div className="-text">200+</div>
                                </div>
                                <div className="-card">
                                    <img src={Rocket} alt="" className="-icon"/>
                                    <div className="-text">Total projects</div>
                                    <div className="-text">70+</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="-container">
                    <div className="-get-access">
                        <div className="-title">Looking for Ways to Supercharge your Idea?</div>
                        <div className="-block">
                            <div className='-text'>Forcefi brings together the best brains of Web3 to support the launch and growth of your project. Get access to opportunities, talent, incentives, and a meaningful network.</div>
                            <ConnectButton buttonType={'-button'} homePageNavigation={true} buttonName={"Get Access Now"}/>
                        </div>
                    </div>
                    <div>
                        <div className="-block-head">
                            <div className="-title">We help ambitious teams realize their vision</div>
                        </div>
                        <div className="-futures">
                            <div className="-box wide">
                                <div>
                                    <div className="-title offset">Token launch functionality</div>
                                    <div className="-text">Launch your token in a highly customizable way, using your smart contract or ours</div>
                                </div>
                                <a href="https://docs.forcefi.io/platform-features/token-sales" target="_blank" className="-text b">
                                    Explore →
                                </a>
                                <img className='-img topology' src={Topology} alt=""/>
                            </div>
                            <div className="-wrapper">
                                <div className="-box">
                                    <div>
                                        <div className="-title">Access to <br/> curators</div>
                                        <div className="-text small-width">Connect with KOLs, advisors, and industry experts that ‘curate’ your project</div>
                                    </div>
                                    <img className='-img top' src={BurstCircle} alt=""/>

                                </div>
                                <div className="-box">
                                    <div>
                                        <div className="-title">Long-term incentives</div>
                                        <div className="-text">We reward listed projects with token rewards for continuous project growth</div>
                                    </div>
                                    <img className='-img' src={Progress} alt=""/>

                                </div>
                            </div>
                            <div className="-box ">
                                <div>
                                    <div className="-title">Advisory & Support</div>
                                    <div className="-text small-width">Free consultations, weekly calls, & warm intros from the Forcefi team</div>
                                </div>
                                <a href="https://docs.forcefi.io/background#web3-bd-and-marketing-are-inefficient" target="_blank" className="-text b">
                                    Explore →
                                </a>
                                <img className='-img looper' src={Looper} alt=""/>

                            </div>
                        </div>
                    </div>

                    <div className="-curators">
                        <div className='-block'>
                            <div className="-title">Work with Curators</div>
                            <div className="-text">Curators review listed projects, offer feedback, and assist teams. Elevate your network, marketing, BD, and traction, by working with the right people. Check our docs for more details.</div>
                            <a href='https://docs.forcefi.io/platform-features/curators' target='_blank'>
                                <button className="-button">Learn More</button>
                            </a>
                        </div>
                        <CuratorSlider />
                    </div>
                </div>
                <div className="-container wide">
                    <div className="-block-head">
                        <div className="-title">Get support from the best in the industry</div>
                    </div>
                    <Partners />
                    <div>
                        <div className="-block-head">
                            <div className="-title">Roadmap heading ....</div>
                        </div>
                        <Roadmap />
                    </div>

                </div>
                <div className="-container">
                    <div className="-block-head">
                        <div className="-title left">Token Distribution</div>
                    </div>
                    <Distribution />
                    <div className="-block-head">
                        <div className="-title">Token Utility</div>
                        <div className="-title second">Join for free and upgrade based on the needs of your project.</div>
                    </div>
                    <TokenUntility />
                    <div>
                        <div className="-block-head">
                            <div className="-title">Choose a Plan that Works for You</div>
                            <div className="-title second">Join for free and upgrade based on the needs of your project.</div>
                        </div>
                        <div className="-plans">
                            <div className="-card">
                                <div>
                                    <div className='-header'>
                                        <div className='-text'>Starter</div>
                                    </div>
                                    <div className='-price'>
                                        $0
                                    </div>
                                    <div className="-benefits-title">INCLUDED</div>
                                    <div className='-benefits'>
                                        <div className="-benefit">
                                            <img src={YES} alt=""/>
                                            <div className='Free project listing'>Free project listing</div>
                                        </div>
                                        <div className="-benefit">
                                            <img src={YES} alt=""/>
                                            <div className='Free project listing'>1 free consultation call with Forcefi team</div>
                                        </div>
                                        <div className="-benefit">
                                            <img src={YES} alt=""/>
                                            <div className='Free project listing'>Access to weekly support and feedback calls</div>
                                        </div>
                                        <div className="-benefit">
                                            <img src={NO} alt=""/>
                                            <div className='Free project listing'>Educational materials</div>
                                        </div>

                                    </div>
                                </div>
                                <ConnectButton buttonType={'-button'} homePageNavigation={true} buttonName={"Get Started"}/>
                            </div>
                            <div className="-card explorer">
                                <div>
                                    <div className='-header'>
                                        <div className='-text explorer'>Explorer</div>
                                        <div className='-label'>BEST CHOICE</div>
                                    </div>
                                    <div className='-price explorer'>
                                        $750
                                    </div>
                                    <div className="-benefits-title explorer">INCLUDED</div>
                                    <div className='-benefits'>
                                        <div className="-benefit">
                                            <img src={YES} alt=""/>
                                            <div className='-text explorer'>All "Starter" benefits</div>
                                        </div>
                                        <div className="-benefit">
                                            <img src={YES} alt=""/>
                                            <div className='-text explorer'>Access to curators</div>
                                        </div>
                                        <div className="-benefit">
                                            <img src={YES} alt=""/>
                                            <div className='-text explorer'>Warm introductions with relevant curators</div>
                                        </div>
                                        <div className="-benefit">
                                            <img src={YES} alt=""/>
                                            <div className='-text explorer'>Co-marketing with Forcefi</div>
                                        </div>

                                    </div>
                                </div>
                                <ConnectButton buttonType={'-button explorer'} homePageNavigation={true} buttonName={"Get Started"}/>
                            </div>
                            <div className="-card">
                                <div>
                                    <div className='-header'>
                                        <div className='-text'>Accelerator</div>
                                    </div>
                                    <div className='-price'>
                                        $2000
                                    </div>
                                    <div className="-benefits-title">INCLUDED</div>
                                    <div className='-benefits'>
                                        <div className="-benefit">
                                            <img src={YES} alt=""/>
                                            <div className='Free project listing'>All “Explorer benefits</div>
                                        </div>
                                        <div className="-benefit">
                                            <img src={YES} alt=""/>
                                            <div className='Free project listing'>Warm introductions to key partners</div>
                                        </div>
                                        <div className="-benefit">
                                            <img src={YES} alt=""/>
                                            <div className='Free project listing'>Token launch support</div>
                                        </div>
                                        <div className="-benefit">
                                            <img src={YES} alt=""/>
                                            <div className='Free project listing'>Featured listing for 3 months</div>
                                        </div>
                                    </div>
                                </div>
                                <ConnectButton buttonType={'-button'} homePageNavigation={true} buttonName={"Get Started"}/>
                            </div>
                        </div>
                    </div>
                    <div className="-join-project">
                        <div className="-title">Ready to Supercharge your Project’s Growth?</div>
                        <ConnectButton buttonType={'-button'} homePageNavigation={true} buttonName={"Join us today!"}/>
                    </div>
                </div>
            </div>
            <EarlyAccessModal show={getEarlyAccess} close={() => setGetEarlyAccess(false)} />
        </>
    );
}
